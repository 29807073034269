import React from 'react'
import { graphql } from "gatsby"
import Image from "gatsby-image"
import ReactMarkdown from "react-markdown"
import Layout from "../components/App/Layout"
import Navbar from "../components/App/Navbar"
import PageBanner from '../components/Common/PageBanner'
import Footer from "../components/App/Footer"
import CaseStudiesSidebar from '../components/CaseStudiesDetails/CaseStudiesSidebar'
 
const Details = ({ data }) => {
    const { title, slug, content, image, subTitle, cliente, ubicacion, website, tecnologias, fecha_completo } = data.project
    return (
        <Layout
            titulo={`${title} - Proyectos`}
            descripcion={`${content.substring(0, 500)}`}
            slug={slug}
            imagen={image.childImageSharp.fluid.src}
        >
            <Navbar />

            <PageBanner
                pageTitle={title}
                homePageText="Home" 
                homePageUrl="/" 
                activePageText={title}
            />

            <section className="case-studies-details-area ptb-100">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-8 col-md-12">
                            <div className="case-studies-details-image">
                                <Image fluid={image.childImageSharp.fluid} />
                            </div>
                            <div className="case-studies-details-desc">
                                <span className="sub-title">{subTitle}</span>
                                <ReactMarkdown source={content} />
                            </div>
                        </div>

                        <div className="col-lg-4 col-md-12">
                            <CaseStudiesSidebar 
                                cliente={cliente}
                                ubicacion={ubicacion}
                                tecnologias={tecnologias}
                                website={website}
                                fechaCompleto={fecha_completo}
                            />
                        </div>
                    </div>
                </div>
            </section>

            <Footer />
        </Layout>
    );
}

export const query = graphql`
    query GetSingleProjects($slug: String) {
        project: strapiProjects(slug: { eq: $slug }) {
            title
            subTitle
            content
            slug
            image {
                childImageSharp {
                    fluid {
                        ...GatsbyImageSharpFluid
                    }
                }
            }
            cliente
            ubicacion
            website
            tecnologias
            fecha_completo (formatString:"MMMM YYYY", locale:"es-mx")
        }
    }
`

export default Details